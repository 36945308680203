import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import router from "@/router";
import UserInfoService from "@/core/services/UserInfoService";

export interface User {
  username: string;
  orgname: string;
  orgcode: string;
  orgbranchcode: string;
  name1: string;
  name2: string;
  phone: string;
  email: string;
  ipaddress: string;
  isu: string;
  roles: [];
  access_token: string;
  accessTokenExp: number;
}

interface Role {
  names: string[];
}

export interface UserAuthInfo {
  errors: string;
  user: User;
  role: Role;
  isFromLogin: boolean;
  isAuthenticated: boolean;
}

/**
 * @description parse token
 * @param token: string
 */
function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = "Нэвтрэх үед алдаа гарлаа.";
  isFromLogin = true;
  user = {} as User;
  role = {} as Role;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get roles
   * @returns string
   */
  get getRoleNames(): string[] {
    return this.role.names;
  }

  /**
   * Get roles
   * @returns string
   */
  get getFromLogin(): boolean {
    return this.isFromLogin;
  }

  /**
   * Get MbUser
   * @returns boolean
   */
  get getMbUser(): boolean {
    return this.user.isu === "0";
  }

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Get current user orgname
   * @returns string
   */
  get orgName(): string {
    return this.user.orgname;
  }

  /**
   * Get current user username
   * @returns string
   */
  get username(): string {
    return this.user.username;
  }

  /**
   * Get current user orgcode
   * @returns string
   */
  get orgCode(): string {
    return this.user.orgcode;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): string {
    return this.errors;
  }

  /*********************************************************************************************************************************************************************
   ****************************************************************** Mutation *****************************************************************************************
   *********************************************************************************************************************************************************************
   *********************************************************************************************************************************************************************/

  @Mutation
  [Mutations.SET_FROM_LOGIN](value) {
    this.isFromLogin = value;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](payload) {
    this.isAuthenticated = true;
    this.user = payload.data;
    const parsedToken = parseJwt(this.user.access_token);
    this.user.accessTokenExp = parsedToken["exp"];
    this.errors = "";
    JwtService.saveToken(this.user.access_token, this.user.accessTokenExp);
    this.role.names = parsedToken["roles"];
    UserInfoService.saveInfo(this.user.username, this.user.accessTokenExp);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.isFromLogin = true;
    this.user = {} as User;
    this.role = {} as Role;
    this.errors = "";
    JwtService.destroyToken();
    UserInfoService.destroyInfo();
  }

  /*********************************************************************************************************************************************************************
   ****************************************************************** ACTION *******************************************************************************************
   *********************************************************************************************************************************************************************
   *********************************************************************************************************************************************************************/

  @Action
  [Actions.SET_FROM_LOGIN](value) {
    this.context.commit(Mutations.SET_FROM_LOGIN, value);
  }

  @Action({ rawError: true })
  [Actions.LOGIN](credentials) {
    const payload = {
      credentials: "",
      data: ""
    };
    return new Promise<void>((resolve, reject) => {
      ApiService.postWithRawBody("login", credentials)
        .then(({ data }) => {
          if (data.code === 1) {
            payload.credentials = credentials;
            payload.data = data;
            this.context.commit(Mutations.SET_AUTH, payload);
            resolve(data);
          } else {
            reject(data.error);
          }
        })
        .catch(({ response }) => {
          const err = response ? response.data.error : "Алдаа гарлаа.";
          this.context.commit(Mutations.SET_ERROR, err);
          reject(err);
        });
    });
  }

  @Action
  [Actions.SIGNIN]() {
    ApiService.setHeader();
    ApiService.get("signin").catch(() => {
      this.context.commit(Mutations.PURGE_AUTH);
      router.push({ name: "login" });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("registration", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.postAnonymous("forgotpassword", { params })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD_STEP_TWO](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.postAnonymous("forgotpasswordverifycode", { params })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("verify")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.TOKEN_EXPIRES]() {
    ApiService.setHeader();
    ApiService.get("verify").catch(() => {
      this.context.commit(Mutations.PURGE_AUTH);
      router.push({ name: "login" });
    });
  }

  @Action
  async [Actions.REFRESH_TOKEN]() {
    let response;
    const payload = {
      credentials: "",
      data: ""
    };
    try {
      response = await ApiService.get(`auth`);
      payload.data = response.data;
      this.context.commit(Mutations.SET_AUTH, payload);
      return { success: true, data: response.data };
    } catch (e) {
      const err = "Хугацаа сунгах боломжгүй байна. Дахин нэвтэрнэ үү.";
      this.context.commit(Mutations.SET_ERROR, err);
      return { success: false, data: err };
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
