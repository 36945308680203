import { CookieComponent } from "@/assets/ts/components/_CookieComponent";

const ID_EXP_KEY = "EXP" as string;

/**
 * @description get info form cookie
 */
export const getInfo = (): string | null => {
  let info = CookieComponent.get("username");
  while (info === undefined) {
    info = CookieComponent.get("username");
  }
  return info;
};

/**
 * @description get exp time form cookie
 */
export const getExpTime = (): number => {
  let exp = CookieComponent.get(ID_EXP_KEY);
  while (exp === undefined) {
    exp = CookieComponent.get(ID_EXP_KEY);
  }
  return Number(exp);
};

/**
 * @description save info into cookie
 * @param username: string
 * @param tokenExpTime: number
 */
export const saveInfo = (username: string, tokenExpTime: number): void => {
  CookieComponent.set("username", username, {
    expires: new Date(tokenExpTime * 1000)
  });
  CookieComponent.set(ID_EXP_KEY, tokenExpTime, {
    expires: new Date(tokenExpTime * 1000)
  });
};

/**
 * @description remove info form cookie
 */
export const destroyInfo = (): void => {
  CookieComponent.delete("username");
  CookieComponent.delete(ID_EXP_KEY);
};

export default { getInfo, saveInfo, getExpTime, destroyInfo };
