import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { publicPath } from "../../vue.config";
import JwtService from "@/core/services/JwtService";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/reference",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "reference",
        name: "reference",
        meta: { authorize: "ROLE_NEW_USER", main: "" },
        // meta: { authorize: "ROLE_GET_INQUIRY", main: "" },
        component: () => import("@/views/Reference.vue")
      },
      /**
       *
       * CHANGED202312 ROLE_NEW_USER нэмэв
       *
       * */
      {
        path: "/reference/custdetails",
        redirect: "/404"
      },
      {
        path: "/reference/custdetails/:id",
        name: "refcustomerdetails",
        meta: { authorize: "ROLE_NEW_USER" },
        // meta: { authorize: "ROLE_MORE_TCUSTOMER" },
        component: () => import("@/views/customer/CustomerDetails.vue")
      },
      {
        path: "/reference/custdetails/loandetails",
        redirect: "/404"
      },
      {
        path: "/reference/custdetails/loandetails/:id",
        name: "refloandetails",
        meta: { authorize: "ROLE_MORE_TLOAN" },
        component: () => import("@/views/customer/LoanDetails.vue")
      },
      {
        path: "/inquiryreference",
        name: "inquiryreference",
        meta: { authorize: "ROLE_INQUIRY_REPORT", main: "" },
        component: () => import("@/views/InquiryReference.vue")
      },
      {
        path: "/listpayments",
        name: "listpayments",
        meta: { authorize: "ROLE_PAID_INFO", main: "" },
        component: () => import("@/views/ListPayments.vue")
      },
      {
        path: "/bankspayment",
        name: "bankspayment",
        meta: { authorize: "ROLE_PAYMENT_INFO", main: "" },
        component: () => import("@/views/BanksPayment.vue")
      },
      {
        path: "/users",
        name: "users",
        meta: { authorize: "ROLE_MB_BRANCH_USER", main: "" },
        component: () => import("@/views/Users.vue")
      },
      {
        path: "/userrole",
        name: "userrole",
        meta: { authorize: "ROLE_USER_ROLE_SETTINGS_MENU", main: "" },
        component: () => import("@/views/UserRole.vue")
      },
      {
        path: "/uncheck",
        name: "uncheck",
        meta: { authorize: "ROLE_IT_USER", main: "" },
        component: () => import("@/views/UncheckRegister.vue")
      },
      {
        path: "/organization",
        name: "organization",
        meta: { authorize: "ROLE_MANAGE_ORG", main: "" },
        component: () => import("@/views/Organization.vue")
      },
      {
        path: "/loan/form",
        name: "loanform",
        meta: { authorize: "ROLE_FORM_MENU", main: "" },
        component: () => import("@/views/form/LoanForm.vue")
      },
      {
        path: "/loan/list",
        name: "loanlist",
        meta: { authorize: "ROLE_FORM_MENU", main: "" },
        component: () => import("@/views/form/LoanList.vue")
      },
      {
        path: "/loan/loandetails",
        redirect: "/404"
      },
      {
        path: "/loan/loandetails/:id",
        name: "loandetails",
        meta: { authorize: "ROLE_FORM_MENU" },
        component: () => import("@/views/form/LoanDetails.vue")
      },
      {
        path: "/loan/custdetails",
        redirect: "/404"
      },
      {
        path: "/loan/custdetails/:id",
        name: "customerdetails",
        meta: { authorize: "ROLE_FORM_MENU" },
        component: () => import("@/views/form/CustomerDetails.vue")
      },
      {
        path: "/logtracks/loginfo",
        name: "loginfo",
        meta: { authorize: "ROLE_INQUIRY_LOG", main: "" },
        component: () => import("@/views/log/LogInfo.vue")
      },
      {
        path: "/logtracks/logsystem",
        name: "logsystem",
        meta: { authorize: "ROLE_SYSTEM_LOG", main: "" },
        component: () => import("@/views/log/LogSystem.vue")
      },
      {
        path: "/logtracks/loglogin",
        name: "loglogin",
        meta: { authorize: "ROLE_LOGIN_LOG", main: "" },
        component: () => import("@/views/log/LogLogin.vue")
      },
      {
        path: "/logtracks/logxmlreceive",
        name: "logxmlreceive",
        meta: { authorize: "ROLE_XML_RECEIVE_LOG", main: "" },
        component: () => import("@/views/log/LogXmlReceive.vue")
      },
      {
        path: "/logtracks/logconnection",
        name: "logconnection",
        meta: { authorize: "ROLE_XML_CONN_LOG", main: "" },
        component: () => import("@/views/log/LogConnection.vue")
      },
      {
        path: "/reports/reportindex",
        name: "reportindex",
        meta: { authorize: "ROLE_SIMPLE_REPORT_RPT_MENU", main: "" },
        component: () => import("@/views/reports/ReportIndex.vue")
      },
      {
        path: "/reports/reportsupervisor",
        name: "reportsupervisor",
        meta: { authorize: "ROLE_SUPERVISOR_REPORT", main: "" },
        component: () => import("@/views/reports/ReportSupervisor.vue")
      },
      {
        path: "/listrequest/edit",
        name: "requestedit",
        meta: {
          authorize: "ROLE_REQUEST_REVISE",
          authorizeSecond: "ROLE_REQUEST_APPROVE",
          main: ""
        },
        component: () => import("@/views/requests/RequestEdit.vue")
      },
      {
        path: "/listrequest/delete",
        name: "requestdelete",
        meta: {
          authorize: "ROLE_REQUEST_REVISE",
          authorizeSecond: "ROLE_REQUEST_APPROVE",
          main: ""
        },
        component: () => import("@/views/requests/RequestDelete.vue")
      },
      {
        path: "/listrequest/total",
        name: "requesttotal",
        meta: { authorize: "ROLE_ALL_REQUEST", main: "" },
        component: () => import("@/views/requests/RequestTotal.vue")
      },
      {
        path: "/settings",
        name: "settings",
        meta: { authorize: "ROLE_IT_USER" },
        component: () => import("@/views/Settings.vue")
      },
      {
        path: "/user/profile",
        name: "user-profile",
        meta: {},
        component: () => import("@/views/account/Profile.vue")
      },
      {
        path: "/user/changepassword",
        name: "user-changepassword",
        meta: {},
        component: () => import("@/views/account/ChangePassword.vue")
      },
      {
        path: "changelog",
        name: "changelog",
        meta: { authorize: "ROLE_IT_USER" },
        component: () => import("@/views/resources/Changelog.vue")
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    meta: {},
    component: () => import("@/views/auth/SignIn.vue")
  },
  {
    path: "/resetpassword",
    name: "resetpassword",
    meta: {},
    component: () => import("@/views/auth/ResetPassword.vue")
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    meta: {},
    component: () => import("@/views/error/Error404.vue")
  },
  {
    path: "/500",
    name: "500",
    meta: {},
    component: () => import("@/views/error/Error500.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    // redirect: "404",
    meta: {},
    component: () => import("@/views/error/Error404.vue")
  }
];

const router = createRouter({
  history: createWebHistory(publicPath),
  routes
});

router.beforeEach((to, from, next) => {
  const loggedIn = JwtService.getToken();
  const { authorize } = to.meta;
  const { authorizeSecond } = to.meta;
  let roles = store.getters.getRoleNames;

  const paramedRoutes = [
    "refcustomerdetails",
    "refloandetails",
    "customerdetails",
    "loandetails"
  ];

  if (roles === undefined) {
    roles = ["ROLE_UNKNOWN"];
  }

  if (to.name !== "login" && !loggedIn) {
    if (to.name === "resetpassword") {
      next();
      return;
    } else {
      next({ name: "login" });
      return;
    }
  } else {
    if (
      roles.includes(authorize) ||
      roles.includes(authorizeSecond) ||
      roles.includes("ROLE_ADMIN") ||
      authorize === undefined
    ) {
      if (paramedRoutes.find(e => e === to.name) === undefined) {
        next();
        return;
      }
      try {
        decodeURIComponent(
          window
            .atob(to.params.id as string)
            .split("")
            .map(function(c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );
        next();
        return;
      } catch (e) {
        next({ name: "500" });
        return;
      }
    } else {
      router.options.routes[0].children?.some(function(ro) {
        if (ro.meta) {
          if (
            (roles.includes(ro.meta["authorize"]) ||
              roles.includes(ro.meta["authorizeSecond"])) &&
            ro.meta["main"] !== undefined
          ) {
            const name = ro.name === undefined ? "" : ro.name.toString();
            next({ name: name });
            return name;
          }
        }
      });
    }
  }
});

export default router;
