import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

config.rawError = true;
const ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "zms-cookie",
  isCompression: false
});

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule
  },
  plugins: [
    createPersistedState({
      paths: ["AuthModule"],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ]
});

export default store;
