import { createApp } from "vue";
import App from "./App.vue";
/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import VueNumberFormat from "vue-number-format";
// import VueNumeric from "vue-numeric";
//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/keenthemes";
import "bootstrap";
import moment from "moment";
import { VueReCaptcha } from "vue-recaptcha-v3";

const app = createApp(App);

const siteKey = process.env.VUE_APP_CAPTCHA_SITE_KEY;

app.use(VueReCaptcha, {
  siteKey: siteKey
});

app.use(store);
app.use(VueNumberFormat, {
  prefix: "",
  suffix: "",
  decimal: ".",
  thousand: ",",
  precision: 2,
  acceptNegative: true,
  isInteger: false
});
app.use(router);
app.use(ElementPlus);
ApiService.init(app);

// MockAdapter.init(app);
// app.use(i18n);

initInlineSvg(app);
initVeeValidate();
moment.suppressDeprecationWarnings = true;

app.config.globalProperties.$filters = {
  formatPrice(row, col, value) {
    const val = (value / 1).toFixed(2).replace(".", ".");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  formatDateYMDHMS(row, col, value) {
    const pattern = "YYYY-MM-DD HH:mm:ss";
    return value ? moment(value).format(pattern) : "";
  },
  formatDateYMD(row, col, value) {
    const pattern = "YYYY-MM-DD";
    return value ? moment(value).format(pattern) : "";
  },
  moment: function(date) {
    return date ? moment(date).format("MMMM Do YYYY, h:mm:ss a") : "";
  }
};

app.mixin({
  methods: {
    b64EncodeUnicode: function(str) {
      return window.btoa(
        encodeURIComponent(str).replace(
          /%([0-9A-F]{2})/g,
          function toSolidBytes(match, p1) {
            return String.fromCharCode(Number("0x" + p1));
          }
        )
      );
    },
    b64DecodeUnicode: function(str: string) {
      return decodeURIComponent(
        window
          .atob(str)
          .split("")
          .map(function(c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      ) as string;
    }
  }
});

app.mount("#app");
