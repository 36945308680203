import { CookieComponent } from "@/assets/ts/components/_CookieComponent";

const ID_TOKEN_KEY = "JVuaxAEFiJxiHiULJ" as string;

/**
 * @description get token form Cookie
 */
export const getToken = (): string | null => {
  const token = CookieComponent.get(ID_TOKEN_KEY);
  return token === undefined ? null : token;
};

/**
 * @description save token into localStorage
 * @param token: string
 * @param exp: number
 */
export const saveToken = (token: string, exp: number): void => {
  CookieComponent.set(ID_TOKEN_KEY, token, {
    expires: new Date(exp * 1000)
  });
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  CookieComponent.delete(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };
